#inicioDashboard{
    display: flex;
    width: 100%;
    height: calc(100vh - 87px);
    margin-bottom: 0;
    overflow-y: hidden;
}

.lottieAnimation{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    user-select: none;
    pointer-events: none;
}
