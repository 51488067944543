.buttom{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 55px;
    box-sizing: border-box;
    background: #512F83;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    border: none;
   
}
.buttom:hover{
    background: #533680;
    color: white;
    
}
.buttom::after{
    position: absolute;
    content: "\00bb";
    opacity: 0;
   
    margin-left: 5px;
}
.buttom:hover::after{
    position: absolute;
    transition: all 0.5s;
    opacity: 1;
    right: 15px;
}