
.drag-n-drop{
    display: flex;
    width: 100%;
    background: #ffffff;
    padding: 15px;
    box-sizing: border-box;
    overflow-x: auto;
    

}
.dnd-group{
    display: flex;
    flex-direction: column;
    background: #5c80ff;
    min-width: 280px;
    height: min-content;
    margin: 5px;
    padding: 10px;
    box-sizing: border-box;
    gap: 5px;
    border-radius: 5px;
    align-items: center;
}
.dnd-group p{
    font-weight: bold;
    font-size: 17px;
    color: black;
}
.actions-group{
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.actions-group svg{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
}

.dnd-item{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dfdfdf;
    border-radius: 10px;
    min-height: 150px;
    width: 100%;
    cursor: grab;
    padding: 10px;
    box-sizing: border-box;
    max-width: 250px;
    max-height: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
}
#hiddenItem{
    filter: blur(5px);
    user-select: none;
}

.current{
    background-color: #282c340c;
}
.current p {
    display: none;
}

.addItem{
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    margin-top: 15px;
    color: white;
    cursor: pointer;
    border-radius: 50%;
    
}
.addItem:hover{
    transition: all 0.2s ease-in-out;
    color: #362753;
}

.addGrupo{
    display: flex;
    flex-direction: column;
    background: #5c80ff;
    min-width: 100px;
    height: min-content;
    margin: 5px;
    padding: 0px 40px;
    box-sizing: border-box;
    gap: 5px;
    border-radius: 5px;
    align-items: center;
    cursor: pointer;
}



