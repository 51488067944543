
html{
    font-family: Verdana, Geneva, Tahoma, sans-serif ;
}
h1,h2,h3,h4,p{
    color: #fff;
}
*{
    list-style: none;
}
#header{
    display: flex;
    max-width: 1300px;
    height: 100px;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    
}


#header img{
    width: 200px;
    height: 80px;
    z-index: 999;
}

#menu {
    
    display: flex;
    width: 100%;
    list-style: none;
    font-size: 20px;
    
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    flex-wrap: wrap;
}
#menu li a{
    display: flex;
    position: relative;
    justify-content: center;
    list-style: none;
    align-items: center;
    text-decoration: none;
    min-width: 80px;
    gap: 5px;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    
}

#menu li ::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 5px;
    border-radius: 4px;
    bottom: -5px;
    box-shadow: inset 0 0 0 0 #7549ce;
    transition: ease-out 0.4s;
    outline: none;

}
#menu li :hover:after{
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 5px;
    
    border-radius: 4px;
    bottom: -5px;
    box-shadow: inset 155px 0 0 0 #622CCE;

}


@media(max-width:700px){

    #header{
        flex-direction: column;
        height: auto;
    }

    #menu{
        justify-content: center;
        margin: 20px 0 0 0;
        padding: 0;
    }
}