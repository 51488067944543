body{
    font-family: Arial, Helvetica, sans-serif !important;

}
.containerDashboard{
    display: flex;
    width: 100%;
}

.dashboard{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0;
    overflow-x: hidden;
}
.menuDashboard{
    display: flex;
    width: 15%;
    box-shadow: 9px 1px 10px 0px rgba(0,0,0,0.3);
    z-index: 2;
    min-width: 100px;
    max-width: 250px;
    background: #1E1E2F;
    background-image: linear-gradient(270deg,#241a38,#140f23);
}
.menuDashboard a{
    width: max-content;
}
.menuDashboard ul{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    height: calc(100vh - 150px);
    list-style: none;
    padding: 0;
    margin: 150px 0 0 20px;
    
}
.menuDashboard ul li{
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    height: 40px;
    margin:10px 0;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
    gap: 15px;
    list-style: none;
    
    
}
.menuDashboard ul li a {
    color: none;
    list-style: none;
    text-decoration: none;
    
}
.menuDashboard ul li::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 5px;
    border-radius: 4px;
    bottom: -5px;
    box-shadow: inset 0 0 0 0 #7549ce;
    transition: ease-out 0.3s;
    outline: none;

}
.menuDashboard ul li:hover:after{
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 5px;
    border-radius: 4px;
    bottom: -5px;
    box-shadow: inset 160px 0 0 0 #622CCE;

}
.headerDashboard{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 15px 30px;
    box-shadow: 0px 10px 8px -9px #000000;
    z-index: 1;
    box-sizing: border-box;
    background: #1E1E2F;
    background-image: linear-gradient(90deg,#241a38,#140f23);
    gap: 20px;
}
.headerDashboard img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}
.exit{
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.exit:hover{
    transition: all 0.1s ease-in;
    transform: scale(1.05);

}
.exit svg{
    font-size: 25px;
}
.bodyDashboard{
    display: flex;
    width: 100%;
    height: 100%;
    background:#F6F8FA;
  
}




@media (max-width:1250px) {
    .menuDashboard ul {
        margin: 150px 0 0 0;
        width: 100%;
    }
    .menuDashboard ul li {
        justify-content: center;
        width: auto;
        box-sizing: border-box;
        padding: 0 10px;
    }
    .menuDashboard ul li svg{
            font-size: 30px;
    }
    .menuDashboard ul li span{
    display: none;
    } 
}