
#section{
    width: 100%;
    height: 100%;
    padding: 20px;
}
#section h1{
    font-size: 55px;
    line-height: 0px;
}

#headerproject{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
}

#bodyprojects{
    display: flex;
    width: 100%;
    height: 100%;
}

#bodyprojects ul{
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    gap: 40px;
    width: 100%;
    
}
#bodyprojects ul li{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:390px;
    min-height: 300px;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 25px;
    border: 3px solid #5400FF;
    transition: all 0.5s ease;
}
#bodyprojects ul li:hover{
    cursor: default;
    transform: scale(1.05);
    -webkit-box-shadow: 0px 0px 19px 1px rgba(84,0,255,0.4); 
    box-shadow: 0px 0px 19px 1px rgba(84,0,255,0.4);
}

#bodyprojects ul li img{
    display: flex;
    width: 100%;
    height: 180px;
    border-radius: 10px;
    object-fit: cover;
    
}
@media(max-width:1289px){
    #bodyprojects ul{
        justify-content: center;
    }
   
}

@media(max-width:490px){
    #bodyprojects ul li{
        width: 300px;
    }
}