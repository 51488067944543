.loader{
    border-radius: 50%;
    border-top: 1px solid rgba(255, 255, 255, 1);
    border-left: 1px solid rgba(255, 255, 255, 1);
    border-right: 2px solid rgba(255, 255, 255, 0);
    animation: spin 0.575s infinite linear;
}

@keyframes spin{
    to { transform: rotate(360deg);}
}