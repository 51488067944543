

h1,h2,h3,h4{
    color: #fff;
    font-family: Rockwell,Courier Bold,Courier,Georgia,Times,Times New Roman,serif; 
    
}
p{
    font-family: Verdana, Geneva, Tahoma, sans-serif ;

}
a{
  text-decoration: none;
}


.container{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #1E1E2F;
    background-image: linear-gradient(270deg,#241a38,#140f23);
}

/* .minh-100{
    min-height: calc(100vh - 71px);
} */
.container-content{
    max-width: 1300px;
    box-sizing: border-box;
    padding: 0 10px;
}

.BoxNull{
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
}
.BoxNull h2{
    color: black;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #49367c #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #49367c;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }