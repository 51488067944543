
#footer{
    display: flex;
    justify-content: center;
    background: rgb(24, 1, 37);
    width: 100%;
    z-index: 999;
}
#row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1300px;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
}
#row > div{
    display: flex;
    flex: 1;
}

#social{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
#social a:hover{
    transition: all 0.3s ease;
    transform: scale(1.2);
    
}

@media(max-width:550px){
    #row{
        flex-direction: column;
        margin-bottom: 10px;
    }
}